import { graphql } from 'gatsby'
import { HomeScreen } from '@src/components/screens/HomeScreen'

export default HomeScreen

export const pageQuery = graphql`
  query IndexQuery {
    allServicesYaml(filter: { locale: { eq: "ja" } }) {
      edges {
        node {
          icon {
            publicURL
          }
          title
          description
        }
      }
    }
  }
`
